<template>
  <div>
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="邀请码渠道" name="first">
        <vab-query-form style="padding-top: 20px">
          <vab-query-form-left-panel :span="6">
            <el-button :icon="Plus" type="primary" @click="handleEdit($event)">
              添加邀请码渠道
            </el-button>
          </vab-query-form-left-panel>
          <vab-query-form-right-panel :span="18">
            <el-form :inline="true" :model="queryForm" @submit.prevent>
              <el-form-item label="渠道名称">
                <el-input v-model="queryForm.name" clearable placeholder="请输入渠道名称" />
              </el-form-item>
              <el-form-item label="邀请码">
                <el-input v-model="queryForm.code" clearable placeholder="请输入邀请码" />
              </el-form-item>
              <el-form-item label="渠道状态">
                <el-select v-model="queryForm.status" clearable>
                  <el-option label="使用中" :value="'IN_USE'"></el-option>
                  <el-option label="已停用" :value="'DEACTIVATED'"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button :icon="Search" type="primary" @click="queryData">
                  查询
                </el-button>
              </el-form-item>
            </el-form>
          </vab-query-form-right-panel>
        </vab-query-form>
        <el-table v-loading="listLoading" border :data="list">
          <el-table-column fixed label="渠道名称" prop="channelName" width="150" />
          <el-table-column fixed label="邀请码" prop="invitationCode" width="150" />
          <el-table-column label="邀请身份">
            <template #default="{ row }">
              {{ handleUserIdentity(row.identity) }}
            </template>
          </el-table-column>
          <el-table-column label="使用场景">
            <template #default="{ row }">
              {{ userSceneMap[row.useScene] }}
            </template>
          </el-table-column>
          <el-table-column label="生成时间">
            <template #default="{ row }">
              {{ parseTimeFunc(row.createTime) }}
            </template>
          </el-table-column>
          <el-table-column label="报名次数总量">
            <template #default="{ row }">
              {{ row.total }}
            </template>
          </el-table-column>
          <el-table-column label="剩余可报名次数" prop="defineUseNum" />
          <el-table-column label="状态" width="150">
            <template #default="{ row }">
              <el-select v-model="row.status" @change="handleChangstatus(row, $event)">
                <el-option label="使用中" :value="'IN_USE'"></el-option>
                <el-option label="已停用" :value="'DEACTIVATED'"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="已报名人数" prop="signUpNum" show-overflow-tooltip />
          <el-table-column label="签到人数" prop="signInNum" show-overflow-tooltip v-if="type == 'EXHIBITION'" />
          <el-table-column label="操作" fixed="right" width="300">
            <template #default="{ row }">
              <el-button type="primary" @click="handleEdit(row)">
                <vab-icon icon="edit-2-line" />
                编辑
              </el-button>
              <el-button type="primary" @click="handleChangeNum(row)">
                修改次数
              </el-button>
              <el-button type="primary" @click="handleSeeDetail(row)">
                查看数据
              </el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-empty class="vab-data-empty" description="暂无数据" />
          </template>
        </el-table>
        <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />

        <edit ref="editRef" :identityList="identityList" :meetingType='queryForm.meetingType' :meetingCode="queryForm.meetingCode" @fetch-data="fetchData" />
        <sharecodeUseNum ref="sharecodeNumRef" @fetch-data="fetchData"></sharecodeUseNum>
        <shareCodeDetail ref="channelDetailRef"></shareCodeDetail>
      </el-tab-pane>
      <el-tab-pane label="邀请码配置" name="second">
        <sharecodeConfig ref="shareCodeConfigRef"></sharecodeConfig>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import {
  defineComponent,
  defineAsyncComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
  onActivated,
  watch,
} from 'vue'

import { Delete, Plus, Search } from '@element-plus/icons'
import {
  channelInvitationList,
  getIdentity,
  channelInvitationUpdateStatus,
} from '@/api/channel'

import { getRegisterConfigList } from '@/api/exhibitionManage'
import shareCodeDetail from './components/shareCodeDetail.vue'
import sharecodeUseNum from './components/sharecodeUseNum.vue'
import { parseTime } from '@/utils/index'
import sharecodeConfig from './sharecodeConfig.vue'
export default defineComponent({
  name: 'sharecodeChannel',
  components: {
    Edit: defineAsyncComponent(() =>
      import('./components/sharecodeChannelEdit')
    ),
    shareCodeDetail,
    sharecodeUseNum,
    sharecodeConfig,
  },
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      queryForm: {
        pageNum: 1,
        pageSize: 20,
        meetingCode: '',
        code: '',
        name: '',
        status: '',
        meetingType: 'EXHIBITION',
      },
      activeName: 'first',
      total: 0,
      shopList: [],
      listLoading: false,
      list: [],
      layout: 'prev, pager, next',
      editRef: null,
      upLoadRef: null,
      resultRef: null,
      generateWxRef: null,
      exportRegisterRef: null,
      channelDetailRef: null,
      shareCodeConfigRef: null,
      typeOptions: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '申请会议',
          value: 'HOLD_MEETING',
        },
        {
          label: '申请展览',
          value: 'EXHIBITION',
        },
      ],
      identityList: [],
      userSceneMap: {
        FORM: '注册表单',
        TICKET: '购票',
      },
      sharecodeNumRef: null,
      type: proxy.$route.query.meetingType,
    })

    const handleEdit = (row) => {
      if (row.channelId) {
        state.editRef.showEdit(row)
      } else {
        state['editRef'].showEdit()
      }
    }

    const handleDelete = (row) => {
      if (row.id) {
        proxy.$baseConfirm('你确定要删除当前项吗', null, async () => {
          const { msg } = await delCoupon(row.id)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        })
      }
    }
    const queryData = () => {
      console.log(state.queryForm, '====????')
      fetchData()
    }

    const fetchData = async () => {
      state.listLoading = true
      const { data } = await channelInvitationList(state.queryForm)
      state.list = data.data
      state.total = data.total
      state.listLoading = false
    }

    const handleSizeChange = (val) => {
      state.queryForm.pageSize = val
      queryData()
    }
    const handleCurrentChange = (val) => {
      state.queryForm.pageNum = val
      queryData()
    }
    watch(
      () => proxy.$route.query,
      async (newVal, oldVal) => {
        console.log(newVal, oldVal, '===1222222')
        state.type = newVal.meetingType
      }
    )

    onMounted(() => {
      state.queryForm.meetingCode = proxy.$route.query.meetingCode
      state.queryForm.meetingType =
        proxy.$route.query.meetingType || 'EXHIBITION'
      state.type = proxy.$route.query.meetingType || 'EXHIBITION'

      console.log(state.type, '-=====vvv')
      queryData()
      getIdentityData()
    })

    const handleClick = (e) => {
      console.log(e, e.index)
      if (e.index == 1) {
        state.shareCodeConfigRef.queryData()
      } else {
        queryData()
        getIdentityData()
      }
    }

    // 查看数据
    const handleSeeDetail = (row) => {
      state.channelDetailRef.handleOpen(
        row,
        state.queryForm.meetingCode,
        state.type
      )
    }

    const parseTimeFunc = (val) => {
      return parseTime(val)
    }

    const handleUserIdentity = (val) => {
      return state.identityList.find((v) => v.identity == val).name
    }
    const getIdentityData = async () => {
      let { data: identityList } = await getRegisterConfigList(
        state.queryForm.meetingCode
      )
      console.log(identityList)
      state.identityList = identityList

      // let resp = await getIdentity()
      // state.identityList = resp.data
    }

    const handleChangeNum = (row) => {
      state.sharecodeNumRef.showEdit(row)
    }

    const handleChangstatus = async (row, e) => {
      console.log(row, e)
      proxy.$baseConfirm(
        '你确定要修改当前状态吗',
        null,
        async () => {
          const { msg } = await channelInvitationUpdateStatus(row.channelId, e)
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          await fetchData()
        },
        async () => {
          row.status = e == 'IN_USE' ? 'DEACTIVATED' : 'IN_USE'
        }
      )
    }

    return {
      ...toRefs(state),
      handleEdit,
      queryData,
      fetchData,
      handleSizeChange,
      handleCurrentChange,
      handleDelete,
      Delete,
      Search,
      Plus,
      handleSeeDetail,
      parseTimeFunc,
      getIdentityData,
      handleUserIdentity,
      handleChangeNum,
      handleChangstatus,
      handleClick,
    }
  },
})
</script>
<style lang="scss" scoped></style>
